export const ENUM = {
  gender: {
    Male: "男",
    Undefined: "未知",
    Female: "女",
  },
  role: {
    SUPERADMIN: "管理员",
    INSTITUTION: "企业",
  },
};
