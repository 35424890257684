<template>
  <div class="home">
    <Nav></Nav>
    <div class="main">
      <div class="queryArea">
        <el-form :inline="true" :model="filter" class="demo-form-inline">
          <el-form-item label="关键词">
            <el-input @keyup.enter.native="queryList" v-model="filter.key" placeholder="输入姓名档案ID等查询" clearable></el-input>
          </el-form-item>
          <el-form-item label="性别">
            <el-select v-model="filter.gender" placeholder="请选择" name="gender" id="gender" class="gender" clearable>
              <el-option v-for="item in sexOptions" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="年龄">
            <el-input
              style="width:120px"
              @keyup.enter.native="queryList"
              type="number"
              v-model="filter.age"
              :min="0"
              :max="200"
              placeholder="输入年龄"
            ></el-input>
          </el-form-item>
        </el-form>
        <el-form :inline="true" :model="filter" class="demo-form-inline">
          <el-form-item label="就诊时间">
            <el-date-picker v-model="filter.examTimeBegin" type="date" :editable="enterDate" :picker-options="pickerOptionsStart" placeholder="选择日期" value-format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="至">
            <el-date-picker v-model="filter.examTimeEnd" type="date" :editable="enterDate" :picker-options="pickerOptionsEnd" placeholder="选择日期" value-format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
          <el-form-item style="float:right">
            <el-button size="small" type="primary" @click="queryList">查询</el-button>
          </el-form-item>
          <el-form-item style="float:right">
            <el-button size="small" @click="reset">重置</el-button>
          </el-form-item>

        </el-form>
      </div>
      <div class="content">
        <div class="table_title">患者档案列表</div>
        <el-table :data="tableList" style="width: 100%">
          <el-table-column width="200px" prop="patientId" label="档案ID" align="center">
          </el-table-column>
          <el-table-column prop="patientName" label="姓名" align="center">
          </el-table-column>
          <el-table-column prop="gender" label="性别" align="center">
            <template slot-scope="scope">
              {{ENUM.gender[scope.row.gender]}}
            </template>
          </el-table-column>
          <el-table-column prop="age" label="年龄" align="center">
          </el-table-column>
          <el-table-column prop="examTime" label="就诊时间" align="center">
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="primary" size="small" @click="goDetail(scope.row.id)">档案详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination :current-page.sync="pageNo" :page-size="filter.size" :total="total" layout="total, prev, pager, next" @size-change="handleSizeChange" @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from '@/components/Nav'
import Service from '@/api/service'
import { ENUM } from '@/utils/enum'
export default {
  name: "FileList",
  components: {
    Nav
  },
  data() {
    return {
      ENUM: ENUM,
      tableList: [],
      showDialog: false,
      title: '',
      filter: {
        key: '',
        gender: null,
        age: undefined,
        examTimeBegin: '',
        examTimeEnd: '',
        page: 0,
        size: 10
      },
      enterDate: false,
      startDate: null,
      endDate: null,
      pickerOptionsStart: {
        disabledDate: time => {
          let endDateVal = this.filter.examTimeEnd;
          if (endDateVal) {
            return time.getTime() > new Date(endDateVal).getTime()
          }
        }
      },
      pickerOptionsEnd: {
        disabledDate: time => {
          let beginDateVal = this.filter.examTimeBegin;
          if (beginDateVal) {
            return (
              time.getTime() <
              new Date(beginDateVal).getTime() - 1 * 24 * 60 * 60 * 1000
            )
          }
        }
      },
      total: 0,
      pageNo: 1,
      sexOptions: [
        {
          id: 'Male',
          name: '男'
        },
        {
          id: 'Female',
          name: '女'
        }
      ],

    }
  },
  created() {
    let day = new Date();
    this.filter.examTimeBegin = this.timestampToTime(day)
    this.filter.examTimeEnd = this.timestampToTime(day)
    this.getList()
  },
  methods: {
    timestampToTime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear();
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
      let D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
      return Y + '-' + M + '-' + D
    },
    queryList(){
      this.filter.page = 0
      this.filter.size = 10
      this.pageNo = 1
      this.getList()
    },
    async getList() {
      if (this.filter.gender === '') {
        this.filter.gender = null
      }
      let re = await Service.examList(this.filter)
      this.tableList = re.content
      this.total = re.totalCount
    },
    reset() {
      this.filter = {
        key: '',
        gender: null,
        age: undefined,
        examTimeBegin: '',
        examTimeEnd: '',
        page: 0,
        size: 10
      }
      this.pageNo = 1
      this.getList()
    },
    handleSizeChange(val) {
      this.filter.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNo = Number(val)
      this.filter.page = Number(val) - 1;
      this.getList();
    },
    goDetail(id) {
      this.$router.push({
        path: '/fileDetail',
        query: {
          id: id
        }
      })
    }
  }

};
</script>
<style lang="scss" scoped>
.content {
  width: calc(100% - 60px);
  height: calc(100% - 135px);
  background: #fff;
  box-shadow: 4px 4px 9px 0 rgba(0, 0, 0, 0.08);
  position: relative;
  border-radius: 4px;
  padding: 0 30px;
  overflow: auto;
}
.queryArea {
  width: calc(100% - 30px);
  border-radius: 4px;
  height: 90px;
  padding: 15px;
  background: #fff;
  box-shadow: 4px 4px 9px 0 rgba(0, 0, 0, 0.08);
  position: relative;
  margin-bottom: 10px;
}
.table_title {
  padding: 15px 0 10px 0px;
  font-weight: 600;
  color: #666;
}
.gender {
  width: 120px;
}
</style>
