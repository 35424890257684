<template>
  <div class="nav" :style="{backgroundColor:$route.path==='/'?'#e6eeff':'#fff'}">
    MIAS-3000 眼科OCT影像辅助诊断软件
    <div class="account">
      <el-dropdown trigger="hover" @command="handleCommand">
        <span class="el-dropdown-link">
          {{username}}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          <router-link to="/modifyPwd">
            <el-dropdown-item>修改密码</el-dropdown-item>
          </router-link>
          <router-link to="/explain">
            <el-dropdown-item>关于软件</el-dropdown-item>
          </router-link>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div v-if="$route.path!=='/'&&!(roleType==='INSTITUTION'&&$route.path==='/fileList')" class="goBack flex" @click="goBack">
      <div class="icon_back"><i class="el-icon-arrow-left"></i></div>
      <div>&nbsp;&nbsp;返回</div>
    </div>
  </div>
</template>

<script>
import rest from '@/utils/rest'
import VueCookie from "vue-cookie";
export default {
  name: "Nav",
  computed: {
    roleType() {
      return VueCookie.get('roleType')
    },
    username() {
      return VueCookie.get('username')
    }
  },
  methods: {
    handleCommand(e) {
      if (e === 'logout') {
        this.$confirm('确定退出当前账号?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          rest.logout()
          this.$router.push('/login')
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
      }
    },
    goBack() {
      this.$router.go(-1)
    }
  }
};
</script>
<style lang="scss" scoped>
.nav {
  width: 100%;
  height: 40px;
  background: #e6eeff;
  text-align: center;
  position: relative;
  line-height: 40px;
  font-weight: 600;
}
.account {
  position: absolute;
  right: 40px;
  top: 0px;
}
.el-dropdown-link {
  cursor: pointer;
}
.goBack {
  position: absolute;
  left: 30px;
  top: 0px;
  line-height: 40px;
  font-weight: 500;
  cursor: pointer;
}
.el-icon-arrow-left {
  color: #4a82d5;
  font-weight: 600;
  font-size: 22px;
  margin-top: 8px;
}
</style>